body{
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  color: #323232; 
  margin: 0;
}

button{
  border: none;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 660px) {
  #rslt_amnt{
    width: 85% !important; 
    margin: 0 auto; 
  }

  #rslt_hdr{
    display: none !important;
  }

  .rslt_rw{
    width: 85% !important; 
    margin: 0 auto !important; 
    flex-direction: column !important;
    height: 280px !important; 
  }

  .rslt_rw .col_1, .rslt_rw .col_2, .rslt_rw .col_3, .rslt_rw .col_4{
    width: 100% !important; 
    justify-content: center !important;
    align-items: center !important;
    border: none !important; 
    text-align: center; 
    height: 50px !important;
    margin: 0 !important;
    display: flex; 
    flex-direction: row;
  }
  
  #dtls_cntr{
    padding: 10px !important;
  }

  #dtls_attr{
    flex-direction: column !important;
  }

  #dtls_attr > div{
    width: 90% !important; 
  }

  .dtls_ttle{
    margin: 10px 0 0 10px !important; 
  }

  #dtls_hdr{
    display: none !important;
  }

  .dtls_rw{
    justify-content: flex-start !important;
  }

  .dtls_rw > p{
    display: inline-block;
    border: none; 
    padding: 0 5px; 
    width: fit-content !important;
  }

  .dtls_col_4{
    display: none !important; 
  }
}

@media only screen and (max-width: 768px) {
  #lgo{
    margin: 20px auto !important; 
  }

  #srch{
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .dpd{
    width: 85% !important; 
    margin: 5px 0 !important; 
  }

  .dpd ul{
    width: 100% !important;
  }

  #rslt_fltr{
    justify-content: center !important;
    align-items: center !important;
  }

  #srch_btns{
    flex-direction: column !important;
  }
}

@media only screen and (min-width: 660px) {
  #main{
    width: 85% !important;
  } 

  #dpd_brand{
    width: 100% !important;
  }

  .dtls_col_4{
    display: flex !important; 
  }
}

@media only screen and (min-width: 768px) {
  #srch_and_btns{
    flex-direction: column !important; 
  }

  #srch_btns{
    display: none !important;
  }

  #srch_btns_big{
    margin-right: 2%;
    margin-top: 20px;
    display: flex !important; 
    justify-content: flex-end !important;
  }

  .dpd{
    width: 18% !important;
    flex-shrink: 0 !important;
  }

  #dpd_year{
    margin-left: 2% !important;
  }

  #dpd_brand{
    width: 18% !important; 
  }

  #main{
    width: 100% !important;
    padding-left: 1% !important;
    padding-right: 1% !important; 
    box-sizing: border-box !important;
  }
}

@media only screen and (min-width: 1150px) {
  /* .dpd{
    width: 140px !important; 
  }

  #srch_and_btns{
    flex-direction: row !important; 
  }

  #srch_btns_big{
    width: fit-content !important;
    margin-top: 0 !important;
  } */
}

/* MEDIA QUERIES */

#app{
  width: 100%; 
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0; 
  margin: 0; 
}

#hdr{
  width: 100%; 
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: visible; 
}

#main{
  width: 100%; 
  min-height: 300px;
  max-width: 1050px;
  margin-bottom: 100px; 
}

#lgo{
  width: 100px; 
  height: 100px; 
  margin: 50px auto 40px auto; 
}

#lgo img{
  width: 100%; 
  height: 100%; 
}

#srch_and_btns, #srch, #srch_btns, #srch_btns_big{
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%; 
  max-width: 1050px;;
}

#srch_btns_big{
  display: none;
}

#srch_btn, #cln_flt{
  height: 45px; 
  width: 15%;
  border-radius: 5px;
  background: #EC0000; 
  color: white; 
  font-weight: 700; 
  font-size: 13px;
  padding: 0 30px;
  margin: 0; 
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#cln_flt{
  background: #323232;
  margin-left: 15px; 
}

.srch_btn_disabled{
  background: rgba(50,50,50,.3) !important;
  cursor: auto;
}

.dpd{
  display: flex; 
  flex-direction: row;
  align-items: center;
  width: 15%; 
  height: 45px; 
  border: 1px solid #323232; 
  border-radius: 5px; 
  box-sizing: border-box;
  margin-right: 2%;
  position: relative;
  cursor: pointer;
  padding: 0;
}

.dpd_disabled{
  cursor: auto;
  opacity: .4;
}

.dpd ul{
  position: absolute;
  display: block;
  list-style: none;
  top: 60px; 
  left: 0; 
  background: white; 
  border-radius: 5px; 
  width: 120%;
  padding: 0px; 
  max-height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.20);
  z-index: 2;
}

.dpd li{
  display: inline-block;
  width: 100%;
  height: 50px; 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px; 
  font-weight: 500;
  text-align: left;
  padding: 5px 10px;
}

.dpd_sctd, .dpd li:hover{
  cursor: pointer;
  background: rgba(50,50,50,.2);
}

.dpd input{
  flex-grow: 1;
  height: 100%; 
  width: 80px; 
  border: none; 
  outline: none; 
  background: none; 
  font-weight: 700;
  font-size: 13px;
  padding-left: 13px;
  color: #323232;
}

::placeholder { 
  color: #323232;
}

.dpd figure{
  width: 40px; 
  height: 100%;
  flex-shrink: 0;
  margin: 0; 
  padding: 6px; 
  border-left: 1px solid #323232; 
  box-sizing: border-box;
}

.dpd figure img{
  width: 100%; 
  height: 100%;
}

#dpd_brand{
  margin: 0;
}

#rslt_amnt{
  font-weight: 700;
  font-size: 13px;
  margin-top: 70px;
}

#rslt_hdr{
  width: 100%; 
  height: 50px; 
  max-width: 1050px;
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #D8D8D8; 
  border-radius: 5px;
  background: #F7F7F7; 
  margin: 15px 0 20px 0; 
}



#rslt_hdr p{
  border-right: 1px solid #D8D8D8; 
  margin: 0; 
  padding: 0 15px; 
  height: 100%; 
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.col_1{
  width: 25%;
  box-sizing: border-box;
}

.col_2{
  position: relative; 
  width: 15%;
  box-sizing: border-box;
}

.col_3{
  width: 35%; 
  box-sizing: border-box;
}

.col_4{
  width: 25%; 
  border-right: none !important;
  box-sizing: border-box;
}

#rslt_rws{
  margin-top: 15px; 
}

.rslt_rw{
  display: flex; 
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
  border-left: 1px solid #D8D8D8; 
  border-right: 1px solid #D8D8D8; 
  border-top: 1px solid #D8D8D8; 
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
}

.rslt_rw:last-child {
  border-bottom: 1px solid #D8D8D8; 
}

.rslt_rw .col_1, .rslt_rw .col_2, .rslt_rw .col_3, .rslt_rw .col_4{
  padding: 0 15px;
}


.rslt_rw .col_1, .rslt_rw .col_2, .rslt_rw .col_3{
  height: 100%; 
  display: flex; 
  flex-direction: row;
  align-items: center;
  border-right: 1px solid #D8D8D8;
}

.rslt_rw .col_1 figure{
  background: white; 
  width: 75px; 
  height: 75px;
  display: flex; 
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0; 
  margin-right: 15px;
}

.rslt_rw .col_1 figure img{
  max-width: 100%; 
  max-height: 100%; 
  object-fit: fit;
}

#ldng, #dtls{
  top: 0; 
  left: 0; 
  position: fixed;
  width: 100%; 
  height: 100vh; 
  background: rgba(50,50,50,.5);
  display: flex; 
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

#ldng_cntr{
  width: 260px; 
  height: 170px; 
  background: white; 
  border-radius: 5px; 
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#ldng_cntr figure{
  width: 50px; 
  height: 50px; 
}

#ldng_cntr figure img{
  width: 100%; 
  height: 100%;
}

#ldng_cntr p{
  font-weight: 700;
  color: #EC0000;
  margin: 0; 
}

#rslt_fltr{
  margin-top: 30px;
  display: flex; 
  flex-direction: row;
  align-items: center;
}

#ntf{
  width: 300px;
  margin: 0 auto;  
  height: 400px;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  text-align: center;
}

#dtls_cntr{
  width: 740px; 
  max-width: 85%;
  max-height: 90%;
  height: 430px; 
  overflow-y: scroll;
  background: white; 
  border-radius: 5px;
  padding: 30px; 
  box-sizing: border-box;
}

#dtls_attr{
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px; 
}

#dtls_attr > div {
  max-width: 90%;
  flex-grow: 1;
}

#dtls_attr figure{
  align-self: center;
  width: 120px; 
  height: 120px; 
}

#dtls_attr img{
  object-fit: contain;
  width: 100%; 
  height: 100%; 
}

#dtls_attr > div > div{
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dtls_ttle{
  font-size: 19px;
  font-weight: 300;
  margin: 0; 
  margin-left: 30px; 
}

.dtls_attr_ttle{
  min-width: 170px; 
  font-weight: 500;
}

#dtls_hdr{
  width: 100%; 
  height: 50px; 
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #D8D8D8; 
  border-radius: 5px;
  background: #F7F7F7; 
  margin: 15px 0 20px 0; 
}


.dtls_col_1{
  width: 25%;
  box-sizing: border-box;
  padding: 0 15px;
  border-right: 1px solid #D8D8D8; 
}

.dtls_col_2{
  width: 25%;
  box-sizing: border-box;
  padding: 0 15px;
  border-right: 1px solid #D8D8D8; 
}

.dtls_col_3{
  width: 25%;
  box-sizing: border-box;
  padding: 0 15px;
  border-right: 1px solid #D8D8D8; 
}

.dtls_col_4{
  width: 25%;
  border-right: none !important;
  box-sizing: border-box;
  padding: 0 15px;
}

#dtls_hdr p{
  border-right: 1px solid #D8D8D8; 
  margin: 0; 
  padding: 0 15px; 
  height: 100%; 
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#dtls_rws{
  margin-top: 15px; 
}

.dtls_rw{
  display: flex; 
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-left: 1px solid #D8D8D8; 
  border-right: 1px solid #D8D8D8; 
  border-top: 1px solid #D8D8D8; 
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
}

.dtls_rw p{
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.dtls_rw:last-child {
  border-bottom: 1px solid #D8D8D8; 
}

#dtls_ldng{
  margin: 50px auto; 
  width: 50px; 
}

#dtls_ldng img {
  width: 100% 
}

.carousel-root{
  margin-top: 70px; 
  width: 85%;
}

#ctc{
  position: absolute;
  width: 30px;
  margin: 0;
  top: 50%;
  transform: translate(0, -50%);
  right: 15px; 
  cursor: pointer;
}

#ctc img{
  width: 100%; 
}

